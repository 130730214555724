.Footer-container{
    position: relative;
}
.Footer-container>hr{
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1rem 2rem;;
    display: flex;
    flex-direction: column;
    gap:4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social-links{
    display: flex;
    gap:4rem;
}
.social-links >img {
    width: 2rem;
    height: 2rem;
    cursor:pointer;

}
.logo-f > img {
    width: 10rem;
}
.footer-blur{
    width: 22rem;
    height: 30rem;
    right: 35%;
    background: greenyellow; 
}
.footer-blur-2{
    bottom: 0;
    left:35%;
    background:orange;
    width: 26rem;
    height: 12rem;
}